<template>
    <div class="map-page map-page-create-post" :class="{'map-loading': loading }">
        <div class="map-page-content map-center-layout">
            <h1 class="map-page-title">
                <a class="map-back-button" title="Go back" @click="go_back"></a>
                {{ is_new ? 'Create' : 'Edit' }} {{ post_type }} Post
            </h1>
            <loader />
            <form @submit.prevent="login">
                <base-input
                    placeholder="Title"
                    v-model="model.title"
                    :error="get_errors('title')"
                    >
                </base-input>
                <upload-input
                    v-model="model.cover_image"
                    @file="cover_image_changed"
                    icon-right="icon-upload"
                    accept="images"
                    placeholder="Upload post pictures"
                    >
                </upload-input>
                <ul class="map-uploaded-pics" v-if="cover_image">
                    <li>
                        <img :src="(cover_image ? cover_image : get_cover_image())" />
                        <button
                            class="map-remove-picture"
                            v-if="cover_image"
                            @click.prevent="remove_cover_image"
                        >
                            Remove picture <span>X</span>
                        </button>
                    </li>
                </ul>
                <base-textarea
                    v-model="model.summary"
                    :error="get_errors('summary')"
                    placeholder="Summary"
                    >
                </base-textarea>
                <dropdown
                    v-if="post_type !== PostType.LESSON"
                    placeholder="Select your language"
                    v-model="model.topic"
                    mode="tags"
                    :closeOnSelect="false"
                    :createTag="true"
                    :options="topics"
                    >
                </dropdown>
                <dropdown
                    v-if="post_type !== PostType.LESSON"
                    placeholder="Select your countries"
                    v-model="model.countries"
                    mode="tags"
                    :closeOnSelect="false"
                    :options="countries"
                    >
                </dropdown>
                <dropdown
                    v-if="post_type !== PostType.LESSON"
                    placeholder="Select your tags"
                    v-model="model.tags"
                    mode="tags"
                    :closeOnSelect="false"
                    :createTag="true"
                    :options="tags"
                    >
                </dropdown>
                <dropdown
                    placeholder="Status"
                    v-model="model.status"
                    :options="['DRAFT', 'ACTIVE']"
                    >
                </dropdown>
                <dropdown
                    v-if="post_type === PostType.LESSON && (model.elearning && model.elearning.type === 'VIDEO & PRESENTATION')"
                    placeholder="Select resources"
                    v-model="model.resources"
                    mode="tags"
                    :options="resources"
                    >
                </dropdown>
                <label v-if="post_type !== PostType.LESSON">Centers</label>
                <div class="map-center-list" v-if="post_type !== PostType.LESSON">
                    <checkbox v-model:checked="model.social_movement">
                        Social Movement
                    </checkbox>
                    <checkbox v-model:checked="model.countering_opposition">
                        Countering Opposition
                    </checkbox>
                    <checkbox v-model:checked="model.winning_narratives">
                        Winning Narratives
                    </checkbox>
                </div>
                <template v-if="post_type === PostType.EVENT">
                    <date-picker
                        placeholder="Event Start Date"
                        mode="dateTime"
                        v-model="model.start_at"
                        >
                    </date-picker>
                    <date-picker
                        placeholder="Event End Date"
                        mode="dateTime"
                        v-model="model.end_at"
                        >
                    </date-picker>
                </template>
                <checkbox v-if="[PostType.BLOG, PostType.EVENT, PostType.NEWS].includes(post_type)" v-model:checked="model.featured">
                    Featured
                </checkbox>
                <checkbox v-if="post_type === PostType.RESOURCE" v-model:checked="model.media_resource">
                    Media Resource
                </checkbox>
                <checkbox v-if="post_type === PostType.EVENT" v-model:checked="model.online">
                    Is Online
                </checkbox>
                <checkbox v-if="post_type === PostType.BLOG" v-model:checked="model.active_comments">
                    Active Comments
                </checkbox>
                <template v-if="post_type !== PostType.E_LEARNING">
                    <label>Post content</label>
                    <div style="width: 100%; position: relative">
                        <laraberg-editor v-if="!loading" :template="laraberg_template" v-model="model.content" />
                    </div>
                </template>
                <div v-else class="map-lesson-section">
                    <dropdown
                        placeholder="Select e-learning type"
                        v-model="model.type"
                        :disabled="!is_new && has_lessons"
                        :options="['LONG FORM ARTICLES', 'VIDEO & PRESENTATION']"
                        >
                    </dropdown>
                    <button class="map-button map-color-primary map-button-edit" v-on:click="create_lesson">CREATE NEW LESSON</button>
                    <div class="map-table map-lessons-table">
                        <header>
                            <div class="map-table-col">#</div>
                            <div class="map-table-col">Title</div>
                            <div class="map-table-col">Date</div>
                            <div class="map-table-col">Status</div>
                            <div class="map-table-col" v-if="user.role === 'super-admin'">Actions</div>
                        </header>
                        <div class="map-table-body">
                            <p class="map-table-empty" v-if="!has_lessons && !loading">There are no lessons to display.</p>
                            <div
                                class="map-table-row"
                                :class="{'map-table-row-focus': visible_share_popup === lesson.id}"
                                v-for="(lesson, key) in model.lessons"
                                :key="key"
                                >
                                <div class="map-table-col">{{ key + 1 }}</div>
                                <div class="map-table-col">{{ lesson.title || 'Untitled' }}</div>
                                <div class="map-table-col">{{ lesson.created_at }}</div>
                                <div class="map-table-col">{{ lesson.status }}</div>
                                <div class="map-table-col map-no-bg" v-if="user.role === 'super-admin'">
                                    <button
                                        class="map-button map-color-primary map-button-share"
                                        @click="show_share_popup(lesson, key)"
                                        >
                                    </button>
                                    <div class="map-share-popup" v-if="visible_share_popup === lesson.id">
                                        <h5>Share {{ current_post_type }}</h5>
                                        <ul>
                                            <li :class="{'map-expanded': share_model.type === 'all'}" @click="share_model.type = 'all'">Share to all users</li>
                                            <li :class="{'map-expanded': share_model.type === 'email'}" @click="share_model.type = 'email'">
                                                Share via email
                                                <base-input
                                                    type="email"
                                                    placeholder="Email"
                                                    v-model="share_model.email"
                                                    v-if="share_model.type === 'email'"
                                                    >
                                                </base-input>
                                            </li>
                                            <li :class="{'map-expanded': share_model.type === 'public'}" @click="share_model.type = 'public'">
                                                Copy public link
                                                <base-input
                                                    placeholder="Public link"
                                                    v-model="share_model.public_link"
                                                    disabled
                                                    @click="select_all"
                                                    v-if="share_model.type === 'public'"
                                                    >
                                                </base-input>
                                            </li>
                                        </ul>
                                        <div class="map-share-actions">
                                            <button
                                                v-if="share_model.type !== 'public'"
                                                class="map-button map-color-primary map-button-share-post"
                                                @click="share_post(lesson, key)"
                                                >
                                                Share
                                            </button>
                                            <!-- <button
                                                v-else
                                                class="map-button map-color-primary"
                                                @click="generate_public_link(post, key)"
                                                :disabled="share_model.public_link !== ''"
                                                >
                                                Generate public link
                                            </button> -->
                                            <button
                                                class="map-button map-color-primary"
                                                @click="show_share_popup(lesson, key)"
                                                >
                                                Close
                                            </button>
                                        </div>
                                    </div>
                                    <button
                                        class="map-button map-color-primary map-button-edit"
                                        @click="edit_lesson(lesson)"
                                        >
                                    </button>
                                    <button
                                        class="map-button map-color-primary map-button-remove"
                                        @click="remove_lesson(lesson, key)"
                                        >
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <footer>
                    <Button
                        class="map-cancel"
                        @click.prevent="cancel"
                        >
                        CANCEL
                    </Button>
                    <Button
                        class="map-save"
                        @click.prevent="save"
                        >
                        SAVE
                    </Button>
                </footer>
            </form>
        </div>
    </div>
</template>

<script>
    import BaseInput from '@/components/Inputs/BaseInput';
    import BaseTextarea from '@/components/Inputs/BaseTextarea';
    import UploadInput from '@/components/Inputs/UploadInput';
    import Dropdown from '@/components/Inputs/Dropdown';
    import DatePicker from '@/components/Inputs/DatePicker';
    import Checkbox from '@/components/Inputs/Checkbox';
    import Button from '@/components/Buttons/Button';
    import Loader from '@/components/Loader';
    import LarabergEditor from '@/components/LarabergEditor';

    import { CountryService, TopicService, TagService, PostService, PostType, ShareService } from '@/services';

    import useVuelidate from '@vuelidate/core';
    import { required } from '@vuelidate/validators';

    import dayjs from 'dayjs';
    import customParseFormat from 'dayjs/plugin/customParseFormat';

    export default
    {
        name: 'PostsCreate',
        components:
        {
            BaseInput,
            BaseTextarea,
            UploadInput,
            Dropdown,
            DatePicker,
            Checkbox,
            Button,
            Loader,
            LarabergEditor,
        },
        setup()
        {
            return {
                v$: useVuelidate(),
                PostType: PostType,
            };
        },
        data()
        {
            const post_type = this.$route.params.type;
            const model = {
                title: '',
                summary: '',
                cover_image: {},
                countries: [],
                tags: [],
                topic: [] ,
                social_movement: false,
                countering_opposition: false,
                winning_narratives: false,
                content: '',
                status: 'DRAFT',
            };

            switch (post_type)
            {
                case PostType.BLOG:
                    model.featured = false;
                    model.active_comments = false;
                    break;

                case PostType.NEWS:
                    model.featured = false;
                    break;

                case PostType.EVENT:
                    model.featured = false;
                    model.online = false;
                    model.start_at = '';
                    model.end_at = '';
                    break;

                case PostType.E_LEARNING:
                    model.type = '';
                    model.lessons = [];
                    break;

                case PostType.LESSON:
                    model.resources = [];
                    break;

                case PostType.RESOURCE:
                    model.media_resource = false;
                    break;
            }

            return {
                post_type: post_type,
                is_new: !this.$route.params.id,
                loading: true,
                laraberg_template: undefined,
                current_post_type: PostType.LESSON,
                visible_share_popup: 0,
                share_model:
                {
                    type: 'all',
                    email: '',
                    public_link: '',
                },
                country: {},
                countries: [],
                topics: [],
                tags: [],
                resources: [],
                cover_image: '',
                model: model,
            };
        },
        validations()
        {
            return {
                model:
                {
                    title: { required },
                    summary: { required },
                },
            };
        },
        computed:
        {
            user()
            {
                return this.$store.getters['Auth/user'];
            },
            has_lessons()
            {
                return this.model.lessons && this.model.lessons.length;
            },
        },
        async created()
        {
            this.loading = true;

            this.countries = (await CountryService.all()).data;
            this.topics = (await TopicService.all()).data;
            this.tags = (await TagService.all()).data;

            this.countries.forEach((item) =>
            {
                item.value = item.id;
                item.label = item.name;
            });

            if (this.post_type === PostType.LESSON)
            {
                this.resources = (await PostService.all(PostType.RESOURCE)).data.data;

                this.resources.forEach((item) =>
                {
                    item.value = item.id;
                    item.label = item.title;
                });
            }

            if (!this.is_new)
            {
                await this.get_post(this.post_type, this.$route.params.id);

                if (this.model.cover_image)
                {
                    this.cover_image = process.env.VUE_APP_BACKEND_URL + 'api/files/' + this.model.cover_image.id;
                }

                if (this.model.countries)
                {
                    this.model.countries = this.model.countries.map((item) => item.id);
                }
            }

            this.loading = false;
        },
        methods:
        {
            async get_post(type, id)
            {
                const post_data = await PostService.get(type, id, { editable: true });

                this.model = {...this.model, ...post_data.data}

                this.model.social_movement = this.model.centers.includes('Social Movement');
                this.model.countering_opposition = this.model.centers.includes('Countering Opposition');
                this.model.winning_narratives = this.model.centers.includes('Winning Narratives');

                if (this.post_type === PostType.E_LEARNING)
                {
                    if (!Array.isArray(this.model.lessons))
                    {
                        this.model.lessons = [];
                    }
                }

                if (this.post_type === PostType.LESSON && this.model.elearning.type === 'VIDEO & PRESENTATION')
                {
                    this.laraberg_template = [
                        ['blocks/pdf-block'],
                        ['blocks/video-block'],
                        ['blocks/check-quiz-block'],
                        ['blocks/todo-block'],
                    ];
                }

                if (this.post_type === PostType.RESOURCE || this.post_type === PostType.CASE_STUDY)
                {
                    this.model.content = this.model.content.replaceAll('\\/', '/');
                }

                if (this.post_type === PostType.EVENT)
                {
                    dayjs.extend(customParseFormat);

                    this.model.start_at = dayjs(this.model.start_at, 'DD/MM/YYYY hh:mm A').toDate();
                    this.model.end_at = dayjs(this.model.end_at, 'DD/MM/YYYY hh:mm A').toDate();
                }
            },

            async save()
            {
                const is_valid = await this.v$.$validate();

                if (!is_valid)
                {
                    document.querySelector('#app .main-layout > .ps').scrollTo(0, 0, 'smooth');
                    this.$toast.error('Please fill all required fields!');
                    return ;
                }

                let form_data = new FormData();

                const centers = [];

                if (this.model.social_movement)
                {
                    centers.push('Social Movement');
                }

                if (this.model.countering_opposition)
                {
                    centers.push('Countering Opposition');
                }

                if (this.model.winning_narratives)
                {
                    centers.push('Winning Narratives');
                }

                form_data.append('cover_image', this.model.cover_image && this.model.cover_image.name ? this.model.cover_image : (this.model.cover_image && this.model.cover_image.id ? this.model.cover_image.id : ''));

                form_data.append('title', this.model.title);
                form_data.append('summary', this.model.summary);
                form_data.append('countries', this.model.countries);
                form_data.append('tags', this.model.tags);
                form_data.append('topic', this.model.topic);
                form_data.append('centers', centers);
                form_data.append('social_movement', this.model.social_movement ? 1 : 0);
                form_data.append('countering_opposition', this.model.countering_opposition ? 1 : 0);
                form_data.append('winning_narratives', this.model.winning_narratives ? 1 : 0);
                form_data.append('content', this.model.content);
                form_data.append('status', this.model.status);

                switch (this.post_type)
                {
                    case PostType.BLOG:
                        form_data.append('featured', this.model.featured ? 1 : 0);
                        form_data.append('active_comments', this.model.active_comments ? 1 : 0);
                        break;

                    case PostType.NEWS:
                        form_data.append('featured', this.model.featured ? 1 : 0);
                        break;

                    case PostType.EVENT:
                        form_data.append('featured', this.model.featured ? 1 : 0);
                        form_data.append('online', this.model.online ? 1 : 0);
                        // form_data.append('start_at', new Intl.DateTimeFormat('en-US').format(this.model.start_at));
                        // form_data.append('end_at', new Intl.DateTimeFormat('en-US').format(this.model.end_at));
                        form_data.append('start_at', dayjs(this.model.start_at).format('YYYY-MM-DD hh:mm:ss'));
                        if (this.model.end_at)
                        {
                            form_data.append('end_at', dayjs(this.model.end_at).format('YYYY-MM-DD hh:mm:ss'));
                        }
                        else
                        {
                            form_data.append('end_at', dayjs(this.model.start_at).format('YYYY-MM-DD hh:mm:ss'));
                        }
                        break;

                    case PostType.E_LEARNING:
                        form_data.append('type', this.model.type);
                        form_data.append('lessons', this.model.lessons.map((lesson) =>
                        {
                            return lesson.id;
                        }));
                        break;

                    case PostType.LESSON:
                        form_data.append('resources', this.model.resources.join(','));
                        break;

                    case PostType.RESOURCE:
                        form_data.append('media_resource', this.model.media_resource ? 1 : 0);
                        break;
                }

                if (this.is_new)
                {
                    PostService.create(this.post_type, form_data).then(() =>
                    {
                        this.go_back();
                        // this.$router.push({ name: 'PostsList', params: { post_type: this.post_type } });

                        this.$toast.success('Post was successfully created!');
                    },
                    (error) =>
                    {
                        if (error.data.message)
                        {
                            this.$toast.error(error.data.message);
                        }
                        else
                        {
                            this.$toast.error('Something went wrong. Please try again!');
                        }
                    });
                }
                else
                {
                    PostService.update(this.post_type, this.$route.params.id, form_data).then(() =>
                    {
                        this.$toast.success('Post was successfully updated!');

                        if (this.post_type === PostType.LESSON)
                        {
                            window.location = `/admin/${PostType.E_LEARNING}/${this.model.elearning.id}/edit`;
                            // this.$router.push({ name: 'PostsEdit', params: { type: PostType.E_LEARNING, id: this.model.elearning.id } });
                        }
                    },
                    (error) =>
                    {
                        if (error.data.message)
                        {
                            this.$toast.error(error.data.message);
                        }
                        else
                        {
                            this.$toast.error('Something went wrong. Please try again!');
                        }
                    });
                }
            },

            async create_lesson()
            {
                if (this.is_new)
                {
                    alert('You need to save your changes before creating a lesson.');
                    return ;
                }

                PostService.create(PostType.LESSON, { title: '', type: this.model.type, elearning_id: this.$route.params.id || 0 }).then((response) =>
                {
                    this.model.lessons.push(response.data);
                    this.$toast.success('Lesson was successfully created!');
                },
                (error) =>
                {
                    if (error.data.message)
                    {
                        this.$toast.error(error.data.message);
                    }
                    else
                    {
                        this.$toast.error('Something went wrong. Please try again!');
                    }
                });
            },

            edit_lesson(lesson)
            {
                if (this.unsaved_changes && !this.is_new)
                {
                    alert('You need to save your changes before editing a lesson.');
                }
                else
                {
                    window.location = `/admin/lessons/${lesson.id}/edit`;
                    // this.$router.push(`/admin/lessons/${lesson.id}/edit`);
                }
            },

            cancel()
            {
                this.go_back();
            },

            cover_image_changed()
            {
                this.cover_image = URL.createObjectURL(this.model.cover_image);
            },

            remove_cover_image()
            {
                this.model.cover_image = {};
                this.cover_image = undefined;
            },

            async remove_lesson(lesson, index)
            {
                if (confirm('Are you sure you want to remove this lesson?'))
                {
                    await PostService.remove(PostType.LESSON, lesson.id);

                    this.model.lessons.splice(index, 1);
                }
            },

            get_cover_image()
            {
                return (this.model.cover_image ? process.env.VUE_APP_BACKEND_URL + 'api/files/' + this.model.cover_image.id : 0)
            },

            get_errors(field, message)
            {
                const $err = this.v$.model[field];

                if ($err.$error)
                {
                    return message || $err.$errors.map((err) => err.$message).join(' - ');
                }

                return false;
            },

            go_back()
            {
                if (this.post_type === PostType.LESSON)
                {
                    window.location = `/admin/${PostType.E_LEARNING}/${this.model.elearning.id}/edit`;
                    // this.$router.push({ name: 'PostsEdit', params: { type: PostType.E_LEARNING, id: this.model.elearning.id } });
                }
                else
                {
                    this.$router.push({ name: 'PostsList', params: { post_type: this.post_type } });
                }
            },

            async show_share_popup(post)
            {
                if (this.visible_share_popup === post.id)
                {
                    this.visible_share_popup = 0;
                    this.share_model =
                    {
                        type: 'all',
                        email: '',
                        public_link: '',
                    };
                }
                else
                {
                    const response = await ShareService.get_by_post(post.id, this.current_post_type);

                    if (response && response.data.token !== '')
                    {
                        this.share_model.public_link = `${window.location.protocol}//${window.location.host}/shared/public/${response.data.token}`;
                    }
                    else
                    {
                        this.generate_public_link(post);
                    }

                    this.visible_share_popup = post.id;
                }
            },

            async share_post(post)
            {
                let message = 'Are you sure you want to share this lesson?';

                if (this.share_model.type === 'all')
                {
                    message = 'Are you sure you want to share this lesson to all users?';
                }

                if (confirm(message))
                {
                    await ShareService.share(post.id, this.current_post_type, this.share_model).then(() =>
                    {
                        if (this.share_model.type === 'all')
                        {
                            this.$toast.success('Post was successfully shared with all users!');
                        }
                        else
                        {
                            this.$toast.success('Post was successfully shared!');
                        }

                        this.visible_share_popup = 0;
                        this.share_model =
                        {
                            type: 'all',
                            email: '',
                            public_link: '',
                        };
                    },
                    (error) =>
                    {
                        if (error.data.message)
                        {
                            this.$toast.error(error.data.message);
                        }
                        else
                        {
                            this.$toast.error('Something went wrong. Please try again!');
                        }
                    });
                }
            },

            async generate_public_link(post)
            {
                const response = await ShareService.generate_public_link(post.id, this.current_post_type);

                this.share_model.public_link = `${window.location.protocol}//${window.location.host}/shared/public/${response.data.token}`;
            },
        }
    }
</script>
